import React, { useContext, useState, useEffect } from "react"
import styled from 'styled-components'
import { useTheme, ThemeProvider, createTheme } from "@mui/material/styles"
import { makeStyles } from "@material-ui/core/styles"
// import AppBar from "@material-ui/core/AppBar"
import AppBar from "@mui/material/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@mui/material/Typography"
import MenuIcon from "@material-ui/icons/Menu"
import Link from "@mui/material/Link"
import { MenuItem } from "@mui/material"
import Menu from "@material-ui/core/Menu"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import Drawer from "@mui/material/Drawer"
import Box from "@mui/material/Box"
import List from "@mui/material/List"
import Divider from "@mui/material/Divider"
import ListItem from "@mui/material/ListItem"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import InboxIcon from "@material-ui/icons/MoveToInbox"
import MailIcon from "@material-ui/icons/Mail"
import CodeIcon from "@material-ui/icons/Code"
import Brightness4Icon from "@material-ui/icons/Brightness4"
import Brightness7Icon from "@material-ui/icons/Brightness7"
import { ColorModeContext } from "../../layout/layout"
import IconButton from "@mui/material/IconButton"
import Stack from "@mui/material/Stack"
import LinkedInIcon from "@material-ui/icons/LinkedIn"
import FacebookIcon from "@material-ui/icons/Facebook"
import scrollTo from "gatsby-plugin-smoothscroll"
import LogoHorizontal from '../../assets/images/ggwiercenia_horizontal.svg';
import { keyframes } from '@mui/system';
import './style.css'



const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  header: {
    backgroundColor: "#FAFAFA",
  },
  menu: {},
  menuButton: {
    marginRight: theme.spacing(2),
    color: "#013220",
    border: "1px solid #013220",
    "&:hover": {
      color: "#ddd",
      borderColor: "#ddd",
    },
  },
  logoText: {
    [theme.breakpoints.down("xs")]: {
      flexGrow: 1,
    },
    display: "flex",
    alignItems: "center",
    marginRight: "5px",
    color: "#013220",
  },
  logo: {
    paddingRight: "8px",
    color: theme.palette.secondary.main,
  },
  headerOptions: {
    display: "flex",
    flex: 1,
    justifyContent: "flex-end",
  },
  link: {
    width: "10%",
   
    justifyContent: "center !important",
    alignItems: "center",
    textAlign: "center",
    textDecoration: "none",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    
  },
  icon: {
    color: "#fff",
  },
}))

const myEffect = keyframes`
  from {
    transform: translateY(-200%);
  }
  to {
    transform: translateY(0);
  }
`;
const menuItems = [
  {
    menuTitle: "O firmie",
    pageURL: "/",
    id: "about",
  },
  {
    menuTitle: "Nasza oferta",
    pageURL: "/",
    id: "offer",
  },
  {
    menuTitle: "Kontakt",
    pageURL: "/",
    id: "contact",
  },
]

type NavbarProps = {
  changeColor?: () => void
}

const Navbar: React.FC<NavbarProps> = () => {
  const theme = useTheme()
  const colorMode = useContext(ColorModeContext)
  const classes = useStyles(theme)
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  const [state, setState] = useState(false)
  const [scrollToId, setScrollTo] = useState("")



  // const theme = useTheme();

  const toggleDrawer = open => event => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return
    }
    setState(open)
  }

  const list = () => (
    <Box
      sx={{ width: "100%" }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List sx={{ width: "100vw", height: 200 }}>
        {menuItems.map(menuItem => {
          const { id, menuTitle, pageURL } = menuItem
          return (
            <Link
              key={menuTitle}
              href=""
              onClick={e => {
                e.preventDefault()
                setScrollTo(id)
              }}
              sx={{
                color: "#808080",
                textDecoration: "none",
                textAlign: "center",
          
              }}
            >
              <ListItem button key={menuTitle} sx={{ textAlign: "center" }}>
                <ListItemText primary={menuTitle} />
              </ListItem>
            </Link>
          )
        })}
        <Stack
          direction="row"
          spacing={1}
          divider={<Divider orientation="vertical" flexItem />}
          justifyContent="center"
          alignItems="center"
          sx={{ padding: "10px 0" }}
        >
          <Link href="https://linkedin.com">
            <LinkedInIcon   />
          </Link>
          <Link href="https://facebook.com">
            <FacebookIcon />
          </Link>
        </Stack>
      </List>
      <Divider />
    </Box>
  )

  useEffect(() => {
    if (!state && isMobile && scrollToId !== "") {
      scrollTo(`#${scrollToId}`)
    }
    setScrollTo("")
  }, [state, scrollToId])

  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        className={classes.header}
        sx={{ backgroundColor: "headerColor.main", padding: "20px 0"}}
      >
        <Toolbar>
          <Typography
            variant="h6"
            className={classes.logoText}
            sx={{
              color: theme.palette.secondary.main,
            }}
          >
            {/* <CodeIcon className={classes.logo} />  */}
            {/* <Logo style={{maxWidth: "200px", height: "100px"}} /> */}
            <LogoHorizontal style={{maxWidth: isMobile ? "70%" : "300px", height: "100%"}} />
            {/* G&amp;G Wiercenia */}

          </Typography>

          {isMobile ? (
            <>
              <IconButton
                edge="start"
                className={classes.menuButton}
                color="primary"
                aria-label="menu"
                onClick={toggleDrawer(true)}
              >
                <MenuIcon />
              </IconButton>
              <Drawer anchor={"top"} open={state} onClose={toggleDrawer(false)}>
                {list()}
              </Drawer>

              <Menu
                className={classes.menu}
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={open}
                onClose={() => setAnchorEl(null)}
              >
                {menuItems.map(menuItem => {
                  const { id, menuTitle, pageURL } = menuItem
                  return (
                    <MenuItem
                      key={menuTitle}
                      className={classes.link}
                      onClick={e => {
                        e.preventDefault()
                        scrollTo(`#${id}`)
                      }}
                    >
                      <Link
                        href=""
                        sx={{
                          color: "#808080",
                          textDecoration: "none",
                          textAlign: "center",
                        }}
                      >
                        {" "}
                        {menuTitle}
                      </Link>
                    </MenuItem>
                  )
                })}
              </Menu>
            </>
          ) : (
            <Stack direction="row" className={classes.headerOptions}>
              {menuItems.map(menuItem => {
                const { id, menuTitle, pageURL } = menuItem
                return (
                  <MenuItem
                    key={menuTitle}
                    className={classes.link}
                    onClick={e => {
                      e.preventDefault()
                      scrollTo(`#${id}`)
                    }}
                    sx={{
                      
                      '&:hover': {
                        // borderBottom: `4px solid ${theme.palette.secondary.main}`,
                        backgroundColor: 'transparent',
                        // animation: `${myEffect} 3s ease`
                      },
                      '&:hover .slider': {
                        width: '100%',
                      },
                      
                    }}
                  >
                    <Link
                      href=""
                      sx={{
                        // color: "#808080",
                        color: `${theme.palette.secondary.main}`,
                        textDecoration: "none",
                        textAlign: "center",
                    
                      
                      }}
                    >
                      {menuTitle}
                    </Link>
                      <div className="slider"></div>
                  </MenuItem>
                )
              })}
              <Stack
                direction="row"
                spacing={1}
                divider={<Divider orientation="vertical" flexItem />}
                justifyContent="center"
                alignItems="center"
                sx={{padding: "0 20px"}}
              >
                <Link href="https://linkedin.com">
                  <LinkedInIcon style={{maxWidth: "30px"}}/>
                </Link>
                <Link href="https://facebook.com" style={{maxWidth: "30px"}}>
                  <FacebookIcon />
                </Link>
              </Stack>
            </Stack>
          )}
        </Toolbar>
      </AppBar>
    </div>
  )
}

export default Navbar
