import React from "react"
import Grid from "@mui/material/Grid"
import LinkedInIcon from "@material-ui/icons/LinkedIn"
import FacebookIcon from "@material-ui/icons/Facebook"
import Link from "@mui/material/Link"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"

const Footer = () => {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{
        width: "100%",
        backgroundColor: "#000",
        color: "#fff",
        height: 50,
      }}
    >
      <Grid
        item
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Typography
          sx={{
            padding: "0 20px",
          }}
        >
          &copy;2022 by G&G Wiercenia
        </Typography>
        <Stack direction="row" justifyContent="center" alignItems="center">
          <Link href="https://linkedin.com" sx={{ marginRight: "5px" }}>
            <LinkedInIcon />
          </Link>
          <Link href="https://facbook.com">
            <FacebookIcon />
          </Link>
        </Stack>
      </Grid>
    </Grid>
  )
}

export default Footer
