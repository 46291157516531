import { createTheme } from "@material-ui/core/styles"

const theme = {
  palette: {
    primary: {
      main: "#000",
      contrastText: "#fff",
    },
    secondary: {
      main: "#42C0C5",
      contrastText: "#fff",
    },
    neutral: {
      main: "#64748B",
      contrastText: "#fff",
    },
    headerColor: {
      main: "#000",
    },
    typography: {
      fontFamily: [
        "Montserrat",
        "Inconsolata",
        "-apple-system",
        "BlinkMacSystemFont",
        "Segoe UI",
        "Roboto",
        "Helvetica Neue",
        "Arial",
        "sans-serif",
        "Apple Color Emoji",
        "Segoe UI Emoji",
        "Segoe UI Symbol",
      ],
      h1: {
        fontSize: "4.75em",
      },
      h3: {
        fontSize: "3.25em",
      },
      button: {
        textTransform: "none",
      },
    },
  },
} as const

type CustomTheme = {
  [Key in keyof typeof theme]: typeof theme[Key]
}
declare module "@material-ui/core/styles/createTheme" {
  interface Theme extends CustomTheme {}
  interface ThemeOptions extends CustomTheme {}
}

export default theme
