import React, { createContext } from "react"
import Navbar from "../components/navbar"
import Footer from "../components/footer"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import CookieConsent, {
  getCookieConsentValue,
  Cookies,
} from "react-cookie-consent"
import customTheme from "../theme/theme"
import darkTheme from "../theme/dark-theme"
import "normalize.css"
import GlobalStyle from "../style/globalStyles"
import { useTheme } from "@mui/system"
import '../fonts/typography.css'

import useMediaQuery from "@material-ui/core/useMediaQuery"

export const ColorModeContext = createContext({ toggleColorMode: () => {} })

const Layout = ({ children }) => {
  const [mode, setMode] = React.useState<"light" | "dark">("light")
  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode(prevMode => (prevMode === "light" ? "dark" : "light"))
      },
    }),
    [mode]
  )

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          ...(mode === "light" ? customTheme.palette : darkTheme.palette),
        },
      }),
    [mode]
  )
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Navbar />
        {children}
        <Footer />
        <CookieConsent
          location="bottom"
          buttonText="Zgoda"
          cookieName="cookiesConsent"
          style={{
            background: `${theme.palette.secondary.main}`,
            // color: `${theme.palette.secondary.main}`,
            color: `#fff`,
            fontSize: "13px",
            textAlign: "center",
            justifyContent: isMobile ? "center" : "space-between",
          }}
          buttonStyle={{
            background: `${theme.palette.primary.main}`,
          
            padding: "10px 20px",
            color:`${theme.palette.secondary.main}`,
            fontSize: "13px",
            borderRadius: "4px",
            marginRight: "20px",
            margin: isMobile ? "5px 0 10px" : "15px 20px 15px 15px",
          }}
          expires={150}
        >
          Ta strona korzysta z ciasteczek aby świadczyć usługi na najwyższym
          poziomie. Dalsze korzystanie ze strony oznacza, że zgadzasz się na ich
          użycie.
        </CookieConsent>
      </ThemeProvider>
    </ColorModeContext.Provider>
  )
}

export default Layout
