const theme = {
  palette: {
    primary: {
      main: "#BFBFBF",
      contrastText: "#fff",
    },
    secondary: {
      main: "#0B523C",
      contrastText: "#fff",
    },
    neutral: {
      main: "#64748B",
      contrastText: "#fff",
    },
    headerColor: {
      main: "#FAFAFA",
    },
  },
  typography: {
    fontFamily: [
      "Montserrat",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },

} as const

type CustomTheme = {
  [Key in keyof typeof theme]: typeof theme[Key]
}
declare module "@material-ui/core/styles/createTheme" {
  interface Palette {}
  interface Theme extends CustomTheme {}
  interface ThemeOptions extends CustomTheme {}
}

declare module "@mui/material/styles" {
  interface Theme {
    headerColor: {
      main: string
    }
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    headerColor?: {
      main?: string
    }
  }
}

export default theme
